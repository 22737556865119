<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.69219 2.76912C7.85608 2.76912 6.09517 3.49851 4.79684 4.79684C3.49851 6.09517 2.76912 7.85608 2.76912 9.69219C2.76912 10.6013 2.94819 11.5016 3.29611 12.3415C3.64402 13.1815 4.15397 13.9447 4.79684 14.5875C5.43971 15.2304 6.2029 15.7404 7.04285 16.0883C7.88279 16.4362 8.78304 16.6153 9.69219 16.6153C10.6013 16.6153 11.5016 16.4362 12.3415 16.0883C13.1815 15.7404 13.9447 15.2304 14.5875 14.5875C15.2304 13.9447 15.7404 13.1815 16.0883 12.3415C16.4362 11.5016 16.6153 10.6013 16.6153 9.69219C16.6153 7.85608 15.8859 6.09517 14.5875 4.79684C13.2892 3.49851 11.5283 2.76912 9.69219 2.76912ZM3.16506 3.16506C4.89616 1.43395 7.24404 0.461426 9.69219 0.461426C12.1403 0.461426 14.4882 1.43395 16.2193 3.16506C17.9504 4.89616 18.923 7.24404 18.923 9.69219C18.923 10.9044 18.6842 12.1047 18.2203 13.2247C17.9044 13.9874 17.4882 14.7028 16.984 15.3522L23.2004 21.5686C23.651 22.0192 23.651 22.7498 23.2004 23.2004C22.7498 23.651 22.0192 23.651 21.5686 23.2004L15.3522 16.984C14.7028 17.4882 13.9874 17.9044 13.2247 18.2203C12.1047 18.6842 10.9044 18.923 9.69219 18.923C8.47999 18.923 7.27966 18.6842 6.15973 18.2203C5.0398 17.7564 4.02221 17.0765 3.16506 16.2193C2.3079 15.3622 1.62797 14.3446 1.16408 13.2247C0.700187 12.1047 0.461426 10.9044 0.461426 9.69219C0.461426 7.24404 1.43395 4.89616 3.16506 3.16506Z"
    />
  </svg>
</template>
